:root{
  /* --backgnd-color: linear-gradient(to bottom, #eef, #aaf); */
  /* --backgnd-color: #ddd; */
  /* --backgnd-color: #45aba957; */
  --backgnd-color: linear-gradient(#000000, #3da09e57);
  /* --text-color: #4a47a3; */
  --text-color: black;
  --text-color2: #afacff;
  --input-bkgnd: #224;
  --scroll-bkgnd: #222;
  --font-one: 1rem;
  --font-two: 0.7rem;
  /* --btn-backgnd: #baf; */
  --btn-backgnd: black;
  /* --btn-color: #024; */
  --btn-color: #fff;
  --item-border: 1px solid #444;
  --item-margin: 0.5rem auto;
  --item-padding: 0.3rem 1.5rem;
  --btn-padding: 0.3rem 1rem;
  --border-radius: 0.3rem
}

body{
  margin:0;
  padding:0;
  padding-bottom: 5rem;
  box-sizing: border-box;
  color: var(--text-color);
  min-height: 100vh;
  justify-content:center;
  text-align: center;
  font-family: 'Verdana','arial';
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background: var( --backgnd-color);
  position: relative;
}
.info{
  color: var(--text-color);
  font-size: var(--font-one);
}
.form-style{
  width: 100%;
  background: var(--input-backgnd);
  background: #ccc;
  color: var(--text-color);
    color: #333;
  border: var(--item-border);
  outline: none;
  /* display: block; */
  margin: var(--item-margin);
  /* padding: var(--item-padding); */
  font-size: var(--font-one);
  border-radius: var(--border-radius);
  border: none;
  border-bottom: 2px solid var(--text-color);
  padding : 0.7rem;
}
.form-search{
  width: 100%;
  background: var( --backgnd-color);
  background: #ccc;
  color: var(--text-color);
  color: #333;
  border: var(--item-border);
  outline: none;
  display: inline;
  margin: var(--item-margin);
  padding: var(--item-padding);
  font-size: var(--font-one);
  border-radius: var(--border-radius);
  border: none;
  
}
.form-search:focus{
  border-bottom: 2px solid var(--text-color)
}
.form-btn{
  background: var(--btn-backgnd);
  color: var(--btn-color);
  padding: var(--btn-padding);
  padding : 0.7rem;
  font-size: var(--font-one);
  border-radius: var(--border-radius);
  outline: none;
  float:right;
  cursor: pointer;
 
  /* transform:translate(300px,15%); */
  font-weight: 600;
  /* position: absolute; */
}
.search-btn{
  border:none;
  background: none;
  outline: none;
  position: absolute;
  font-size:1.3rem;
  transform: translate(-30px,40%);
  /* right: 20px */
}
.form_box{
  position:relative;
  width: 50vw;
  display:flex;
  align-items:center;
  justify-content:center
}
.search_box{
  position:relative;
  width: 50vw;
 
}
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 90vw;
    height: 100vh;
    background: #ddd;
    transition: all 1s ease-out;
    transform: translate(-100%);
    background: #182c2c;
    z-index: 999;
  }
  .slide {
    transform: translate(0);
    display: block;
  }
  ::-webkit-scrollbar{
    width: 0.9rem;
    height: 10px;
  }
  ::-webkit-scrollbar-track{
    height: 10px;
    background: var(--backgnd-color)
  }
  ::-webkit-scrollbar-thumb{
    background: var(--scroll-bkgnd);
    border-radius: 0.5rem;
  }
@media screen and (max-width: 590px){
  .form_box, .search_box{
      width: 100%;
   }
}
